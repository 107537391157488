<template>
  <div class="bg-whiteBase xl:shadow-inner xl:pt-24">
    <!-- <div class="absolute align-middle bottom-0 right-0">
      <a href="https://wa.me/6281299556161" target="_blank">
        <img
          src="../assets/wa.svg"
          alt=""
          class="w-2/12 h-aut0 fixed z-40 right-0 object-right p-3 -mt-32 -mr-1 md:p-8 md:-mr-4 md:-mt-48 lg:p-14 lg:-mr-9 lg:-mt-52 xl:p-iconWa xl:-mt-56 xl:-mr-16 2xl:-mt-72 2xl:p-32 2xl:-mr-28"
      /></a>
      <svg
        v-show="visible"
        id="backToTopID"
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        fill="currentColor"
        class="bi bi-arrow-up-circle-fill text-blackBlue fixed z-50 right-0 object-right -mt-16 mr-1 px-2 md:px-0 md:mr-6 md:-mt-20 xl:-mt-16 xl:p-1 2xl:-mt-24 2xl:mr-4 cursor-pointer"
        viewBox="0 0 16 16"
        @click="scrollToTop"
      >
        <path
          d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"
        />
      </svg>
    </div> -->
    <!-- Start layanan-Header -->
    <div>
      <img
        src="../assets/background/bg-top.webp"
        alt=""
        class="img1 w-10/12 ml-bgMobile pl-20 backdrop-opacity-80 md:w-11/12 md:ml-bgTab md:pl-40 md:-mt-8 lg:ml-bgLg xl:hidden"
      />
      <h5
        class="tracking-widest -mt-44 mb-2 xl:mt-0 xl:text-2xl text-center lg:text-3xl lg:mt-mtBerandaButtonBottomDekstop text-lg md:-mt-80"
      >
        APPLY TODAY
      </h5>
      <h1
        class="text-center text-2xl mx-6 md:text-4xl xl:mt-0 xl:text-5xl lg:text-5xl"
      >
        BERGABUNGLAH BERSAMA KAMI
      </h1>
      <hr
        class="beranda border mt-2 absolute inset-x-28 md:w-1/4 md:mx-auto xl:w-2/12"
      />
      <p
        class="text-center text-sm mt-8 mx-20 md:mt-14 md:text-lg md:mx-20 lg:text-xl lg:mx-44"
      >
        Kami membuka peluang posisi yang tepat untuk anda, jangan lewatkan
        peluang terbaik yang kami berikan.
      </p>
    </div>
    <div
      class="hidden bg-whiteBase mx-6 shadow-xl mt-8 px-8 pt-6 md:mt-14 md:pt-10 lg:mt-28 lg:mx-8 lg:px-20 lg:pt-14 xl:pt-8 xl:mt-14 xl:w-6/12 xl:mx-auto 2xl:mt-28 2xl:w-4/12 2xl:px-12 relative"
    >
      <div class="relative"></div>
      <img src="../assets/career.svg" alt="" />
      <h4
        class="text-2xl mt-4 font-semibold lg:mt-8 md:text-3xl 2xl:text-2xl 2xl:mt-4 md:my-6 lg:text-4xl xl:text-2xl"
      >
        Belum ada Posisi yang dibuka
      </h4>
      <div class="flex mt-3 xl:-mt-6 xl:-ml-2">
        <div class="flex-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-info-circle-fill md:w-24 md:h-12 md:px-9 md:-ml-8 md:-mt-4 lg:w-28 lg:px-11 lg:mt-1"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
            />
          </svg>
        </div>
        <div class="flex-1 md:-ml-96 md:pl-6">
          <h5
            class="-ml-36 pl-7 leading-tight md:leading-tight md:text-lg lg:mt-4 lg:text-xl lg:-ml-48 xl:-ml-14 2xl:-ml-16"
          >
            Untuk saat ini posisi yang dibuka belum tersedia, tapi kami akan
            terus melakukan pembaruan pada halaman ini jika ada posisi yang kami
            cari dan semoga anda terpilih menjadi bagian anggota kami. Terima
            kasih
          </h5>
        </div>
      </div>
      <img
        src="../assets/logo/logo.png"
        alt=""
        class="opacity-30 w-10/12 ml-auto -mr-8 mt-2 md:w-7/12 md:mt-4"
      />
    </div>


    <!-- We are hiring desktop and form -->
    <div class="hidden xl:grid grid-cols-2 container mx-auto py-20 relative z-50 mt-16">
      <!-- box 1 -->
      <div>
        <h1 class=" text-5xl md:text-7xl text-center">We Are Hiring!</h1>
        <h2 class=" text-3xl md:text-4xl text-center mt-5">Marketing</h2>
        <ol class=" space-y-4 mt-10">
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Berpenampilan menarik</span> 
            </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Pendidikan minimal S1</span> 
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Pria / Wanita</span>
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Komunikatif</span>
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Memiliki kendaraan bermotor</span>
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Mampu bekerja sama dalam tim</span>
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Memiliki pengetahuan dalam bidang otomotif</span>
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Memiliki pengalaman marketing di bidang asuransi</span>
          </li>
          <li class="flex space-x-4 items-start">
            <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
            <span>Mampu bekerja dengan target</span>
          </li>
        </ol>
      </div>

      <!-- box 2 -->
      <div>
        <h4
          class="text-2xl mt-4 xl:text-4xl md:text-3xl text-gray-800 tracking-wider font-semibold 2xl:text-4xl relative 2xl:text-gray-800 text-center"
        >
          Kirim Lamaran Anda
        </h4>
        <p
          class="text-base mt-4 mx-2 text-gray-800 md:text-lg relative text-center"
        >
          Harap dicatat bahwa hanya kandidat terpilih yang akan dihubungi untuk
          wawancara lebih lanjut.
        </p>
        <form v-if="formLamaran" class="w-full px-5" @submit.prevent="onLamaranSend">
          <div class="xl:grid xl:grid-cols-1 xl:gap-6">
            <div>
              <div
                class="items-center border-b border-teal-500 py-2 xl:mt-formSpace"
              >
                <input
                  class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  minlength="3"
                  placeholder="Nama lengkap anda*"
                  aria-label="Full name"
                  v-model="lamaran.nama"
                />
              </div>
              <div
                class="items-center border-b border-teal-500 py-2 my-4 xl:my-7"
              >
                <input
                  class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  minlength="3"
                  placeholder="Alamat anda*"
                  aria-label="Address"
                  v-model="lamaran.alamat"
                />
              </div>
              <div
                class="items-center border-b border-teal-500 py-2 my-4 xl:my-7"
              >
                <input
                  class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  minlength="3"
                  placeholder="Kota"
                  aria-label="City"
                  v-model="lamaran.kota"
                />
              </div>
              <div
                class="items-center border-b border-teal-500 py-2 my-4 xl:my-7"
              >
                <input
                  class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  minlength="3"
                  placeholder="Nomor telepon*"
                  aria-label="Phone Number"
                  v-model="lamaran.telepon"
                />
              </div>

              <div
                class="items-center border-b border-teal-500 py-2 my-4 xl:mb-7 xl:mt-formSpace2"
              >
                <input
                  class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  minlength="3"
                  placeholder="Email anda*"
                  aria-label="Email Address"
                  v-model="lamaran.email"
                />
              </div>


              <div>
                <p class="atau text-left text-sm text-gray-400">
                  <span class="">Upload cover letter</span>
                </p>
                <div class="flex items-center border-b border-teal-500 py-2 my-4">
                  <input
                    class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="file"
                    placeholder="Upload cv anda*"
                    aria-label="Upload cv anda"
                    @change="onCV"
                    accept="application/pdf,application/msword, .docx"
                  />
                  <p
                    class="text-formatfile -ml-28 text-gray-400 xl:text-sm xl:-ml-48"
                  >
                    format file (.doc, .docx, .pdf)
                  </p>
                </div>                
              </div>


              <div>
                <p class="atau text-left text-sm text-gray-400">
                  <span>atau (optional)</span>
                </p>
                <div class="flex items-center border-b border-teal-500 py-2 my-4">
                  <input
                    class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="file"
                    placeholder="Upload cv anda*"
                    aria-label="Upload cv anda"
                    @change="onFile"
                    accept="application/pdf,application/msword, .docx"
                  />
                  <p
                    class="text-formatfile -ml-28 text-gray-400 xl:text-sm xl:-ml-48"
                  >
                    format file (.doc, .docx, .pdf)
                  </p>
                </div>
              </div>
            </div>


            <div class="flex items-start col-span-2">
              <div class="md:w-1/12 mr-10">
                <input v-model="agreeCheck" class="mr-2 leading-tight" type="checkbox">
              </div>
              <label class="md:w-2/3 block text-gray-500 text-left xl:-ml-16">
                <span class="text-sm">
                  Saya setuju bahwa data yang saya kirimkan dikumpulkan dan
                  disimpan
                </span>
              </label>
            </div>
              <!-- <p class="">KIRIM</p> -->
            <div class=" flex justify-center my-10">
              <button :class="{opacity50: !buttonSend}" type="submit"  :disabled="!buttonSend" class="tracking-widest text-whiteBase focus:outline-none bg-brownA  cursor-pointer uppercase inline-block py-3 px-20">
                <span class=" whitespace-nowrap">{{buttonSend ? 'Kirim' : 'Mohon Tunggu'}}</span>
              </button>              
            </div>
          </div>
          <transition name="fade">
            <div class=" bg-red-100 py-2 px-10 rounded-sm " v-if="error">
              <span class=" text-red-600 font-light tracking-wide">{{error}}</span>
            </div>
          </transition>
        </form>
        <div v-else class=" bg-green-100 text-green-800 py-5 inline-block px-20 mt-10 mb-20 rounded-sm border border-green-200">
          <span>Success! Form Lamaran Anda berhasil di kirim</span>
        </div>        
      </div>
    </div>


    <!-- We are hiring mobile -->
    <div class="xl:hidden px-4 py-10 md:px-10 md:py-20 bg-whiteBase shadow-xl rounded-md mx-auto mt-20 w-10/12 md:w-4/5 lg:w-3/4 xl:w-2/4 relative z-50">
      <h1 class=" text-5xl md:text-7xl text-center">We Are Hiring!</h1>
      <h2 class=" text-3xl md:text-4xl text-center mt-5">Insurance Marketing</h2>
      <ol class=" space-y-4 mt-10">
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Pria/Wanita Maks Umur 30 Tahun</span> 
          </li>
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Fresh Graduate</span> 
        </li>
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Lulusan Minimal SMA/SMK</span>
        </li>
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Bersedia bekerja dengan target dan bekerja dengan team</span>
        </li>
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Memiliki kendaraan dan Sim C</span>
        </li>
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Penampilan Menarik dan Komunikatif</span>
        </li>
        <li class="flex space-x-4 items-start">
          <img src="@/assets/icon/check.svg" alt="" class="w-5 mt-0.5">
          <span>Bersedia di tempatkan di ITC BSD</span>
        </li>
      </ol>
    </div>

    <div class="">
      <img
        src="../assets/background/bg-bottom.webp"
        alt=""
        class="-mt-10 opacity-60 md:-mt-32 2xl:mt-mtBerandaLayananBottomDekstop absolute"
      />      
    </div>

    <!-- form lamaran mobile-->
    <div  class="xl:hidden text-center mx-6 mt-40 lamaran relative">
      <h4
        class="text-2xl mt-4 xl:text-4xl md:text-3xl text-gray-800 tracking-wider font-semibold 2xl:text-4xl 2xl:-mt-32 relative 2xl:text-gray-800"
      >
        Kirim Lamaran Anda
      </h4>
      <p
        class="text-base mt-4 mx-2 text-gray-800 md:text-lg md:mx-24 xl:mx-96 2xl:text-gray-800 2xl:mt-8 2xl:px-64 relative"
      >
        Harap dicatat bahwa hanya kandidat terpilih yang akan dihubungi untuk
        wawancara lebih lanjut.
      </p>
      <form v-if="formLamaran" class="w-full my-10 md:px-4 lg:px-6 xl:px-8 2xl:px-60 2xl:mt-16" @submit.prevent="onLamaranSend">
        <div class="xl:grid xl:grid-cols-2 xl:gap-6">
          <div>
            <div
              class="items-center border-b border-teal-500 py-2 xl:mt-formSpace"
            >
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                minlength="3"
                placeholder="Nama lengkap anda*"
                aria-label="Full name"
                v-model="lamaran.nama"
              />
            </div>
            <div
              class="items-center border-b border-teal-500 py-2 my-4 xl:my-7"
            >
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                minlength="3"
                placeholder="Alamat anda*"
                aria-label="Address"
                v-model="lamaran.alamat"
              />
            </div>
            <div
              class="items-center border-b border-teal-500 py-2 my-4 xl:my-7"
            >
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                minlength="3"
                placeholder="Kota"
                aria-label="City"
                v-model="lamaran.kota"
              />
            </div>
            <div
              class="items-center border-b border-teal-500 py-2 my-4 xl:my-7"
            >
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="number"
                minlength="3"
                placeholder="Nomor telepon*"
                aria-label="Phone Number"
                v-model="lamaran.telepon"
              />
            </div>
            <div
              class="items-center border-b border-teal-500 py-2 my-4 xl:mb-7 xl:mt-formSpace2"
            >
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                minlength="3"
                placeholder="Email anda*"
                aria-label="Email Address"
                v-model="lamaran.email"
              />
            </div>
          </div>
          <div class="xl:mt-8">
            <p class="atau text-left text-sm text-gray-400">
              <span class="">Upload cover letter</span>
            </p>
            <div class="flex items-center border-b border-teal-500 py-2 my-4">
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="file"
                placeholder="Upload cv anda*"
                aria-label="Upload cv anda"
                @change="onCV"
                accept="application/pdf,application/msword, .docx"
              />
            </div>
             <p
                class="text-sm text-right text-gray-400 xl:text-sm xl:-ml-48"
              >
                format file (.doc, .docx, .pdf)
              </p>            
            <!-- <div class="items-center border-b border-teal-500 py-2 my-4">
              <textarea
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none h-36"
                type="text"
                placeholder="Cover letter"
                aria-label="Cover Letter"
                v-model="lamaran.cv"
              />
            </div> -->
            <p class="atau text-left text-sm text-gray-400">
              <span>atau (optional)</span>
            </p>
            <div class="flex items-center border-b border-teal-500 py-2 my-4">
              <input
                class="appearance-none text-sm bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="file"
                placeholder="Upload cv anda*"
                aria-label="Upload cv anda"
                @change="onFile"
                accept="application/pdf,application/msword, .docx"
              />
            </div>
             <p
                class="text-sm text-gray-400 xl:text-sm xl:-ml-48 text-right"
              >
                format file (.doc, .docx, .pdf)
              </p>
          </div>
          <div class="flex items-start mb-6 mt-6 xl:col-span-2 xl:-mt-4">
            <div class="md:w-1/12">
              <input v-model="agreeCheck" class="mr-2 leading-tight xl:-ml-16" type="checkbox">
            </div>
            <label class="md:w-2/3 block text-gray-500 text-left xl:-ml-16">
              <span class="text-sm">
                Saya setuju bahwa data yang saya kirimkan dikumpulkan dan
                disimpan
              </span>
            </label>
          </div>
            <!-- <p class="">KIRIM</p> -->
            <button :class="{opacity50: !buttonSend}" type="submit"  :disabled="!buttonSend" class="tracking-widest text-whiteBase focus:outline-none bg-brownA p-2 mb-5 md:w-3/12 md:mt-10 xl:col-span-2 xl:mx-auto xl:mt-0 cursor-pointer uppercase px-20">
              <span class=" whitespace-nowrap">{{buttonSend ? 'Kirim' : 'Mohon Tunggu'}}</span>
            </button>
        </div>
        <transition name="fade">
          <div class=" bg-red-100 py-2 px-10 rounded-sm " v-if="error">
            <span class=" text-red-600 font-light tracking-wide">{{error}}</span>
          </div>
        </transition>
      </form>
      <div v-else class=" bg-green-100 text-green-800 py-5 inline-block px-20 mt-10 mb-20 rounded-sm border border-green-200">
        <span>Success! Form Lamaran Anda berhasil di kirim</span>
      </div>
    </div>

    <!-- End layanan-Header -->
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: "Karir",
  data() {
    return {
      visible: false,
      formLamaran: true,
      cvFile: "",
      otherFile: "",
      agreeCheck: "",
      buttonSend: true,
      lamaran: {
        nama: "",
        alamat: "",
        kota: "",
        telepon: "",
        email: "",
        // cv:"",
        cvFile:"",
        cvFileName:"",
        otherFile:"",
        otherFileName:""
      },
      error: null
    };
  },
  methods: {
    onCV(event) {
      this.cvFile = event.target.files[0]
      if (this.cvFile.size > 1024000) {
        event.target.value = ''
        this.cvFile = ''
        this.error = "The CV file size is too large (max 1mb)"
        setTimeout(() => {
          this.error = null
        }, 4000)
        return
      }
      if(this.cvFile !== '') {
        this.toBase64(this.cvFile)
          .then(result => {
            this.lamaran.cvFile = result
          })
        this.lamaran.cvFileName = this.cvFile.name
      }
      
    },
    onFile(event) {
      this.otherFile = event.target.files[0]
      if(this.otherFile.size > 1024000) {
        event.target.value = ''
        this.otherFile = ''
        this.error = "The Other CV file size is too large (max 1mb)"
        setTimeout(() => {
          this.error  = ''
        }, 4000)
        return
      }
      if(this.otherFile !== '') {
        this.toBase64(this.otherFile)
          .then(result => {
            this.lamaran.otherFile = result
          })
        this.lamaran.otherFileName = this.otherFile.name  
      }
    },
    async onLamaranSend() {
      if(this.lamaran.name === '' || this.lamaran.alamat === '' || this.lamaran.kota === '' || this.lamaran.telepon === '' || this.lamaran.email === '' || this.cvFile === '') {
        this.error = "Mohon lengkapi form pendaftaran"
        setTimeout(() => {
          this.error = ''
        }, 4000)
        return
      }
      if(!this.agreeCheck) {
        this.error = "Mohon Setujui \"bahwa data yang saya kirimkan dikumpulkan dan disimpan\""
        setTimeout(() => {
          this.error = ''
        }, 4000)
        return
      }
      if (!this.validEmail(this.lamaran.email)) {
        this.error = "The email address is badly formatted."
        setTimeout(() => {
          this.error = ''
        }, 4000)
        return 
      }

      try {
        this.buttonSend = false
        const result = await axios.post('https://troyaindo.co.id/.netlify/functions/lamaran', this.lamaran)
        if(result.status === 200) {
          this.formLamaran = false
        }
      } catch (error) {
        console.log(error)
        this.error = "Maaf gagal mengirim lamaran, hubungi +62 812 9955 6161"
      } finally {
        this.buttonSend = true
      }

    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },    
  },
};
</script>

<style>
.opacity50 {
      opacity: 0.5;
      cursor: default;
}
.bg-css {
  z-index: 0 !important;
}
.lamaran {
  z-index: 99999 !important;
} 
p.atau {
  position: relative;
}

p.atau span {
  padding-right: 10px;
}

p.atau:after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 8rem;
  right: 0;
  height: 0.5em;
  border-top: 1px solid rgb(172, 172, 172);
  /* z-index: -1; */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
